import React from 'react';

const NotFound = () => (
    <div className="wrapper">
        <h3 className="text-center">Ошибка 404. Страница не найдена.</h3>
        <p className="text-center">К сожалению, страница, которую вы ищете, не существует.</p>
    </div>
);

export default NotFound;
