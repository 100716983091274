import React, { Component } from 'react';

class ErrorBoundary extends Component {

    state = {
        hasError: false
      };

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
       // Можно использовать для отправки ошибок на сервер.
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="wrapper">
                    <h3 className="text-center text-danger">Что-то пошло не так.</h3>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
