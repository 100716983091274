import React, { Suspense, lazy, createElement } from 'react'
import { BrowserRouter, HashRouter, Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from './Route/PrivateRoute';
import { AuthUser } from 'components/Auth';
import NotFound from './basic/NotFound';
import ErrorBoundary from './basic/ErrorBoundary';
import { Callback } from './Auth/Callback';

const SmsLayout = lazy(() => import("layouts/Sms"));

const App = () => {
    const layouts = {};
    layouts['sms'] = SmsLayout;

    return (
        <ErrorBoundary>
            <AuthUser>
                <HashRouter>
                    <Suspense fallback={<div>Загрузка...</div>}>
                        <Switch>
                            <Route exact path="/signin-oidc" component={Callback} />
                            <PrivateRoute 
                                path="/:layout/:component"
                                render={props => 
                                    createElement(layouts[props.match.params.layout] 
                                        ? layouts[props.match.params.layout] 
                                        : NotFound,
                                        { ...props })} />
                            <Redirect from="/" to="/sms/dashboard" />
                        </Switch>
                    </Suspense>
                </HashRouter>
            </AuthUser>
        </ErrorBoundary>
    )
}

export default App
