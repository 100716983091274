import React from 'react'
import { Route } from "react-router-dom";
import useAuth from 'hooks/basic/useAuth';

const PrivateRoute = (props) => {
    const { render } = props;
    const { signinRedirect, isAuthenticated } = useAuth();
    if (isAuthenticated()) {

        return <Route {...props} render={(props) => render(props)} />;

    } else {
        signinRedirect();
        return <span>Загрузка...</span>;
    }

}

export default PrivateRoute

