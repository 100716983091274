const AUTH_CONFIG = {
    authority: window._env_?.AUTH_URL ?? "https://connectdis.norbit.ru",
    client_id: "smsc",
    response_type: "code",
    scope: "openid profile smsc_service",
    redirect_uri: `${window.location.origin}/#/signin-oidc`,
    silent_redirect_uri : `${window.location.origin}/silent-refresh.html`,
    post_logout_redirect_uri : `${window.location.origin}`,
    automaticSilentRenew: true,
    includeIdTokenInSilentRenew: false,
    changePwdUrl: "/account/changepassword"
}

export default AUTH_CONFIG;
