import React, { useEffect } from "react";
import useAuth from 'hooks/basic/useAuth';
import { useHistory } from "react-router-dom";

export const Callback = () => {

    const { signinRedirectCallback } = useAuth();
    let history = useHistory();

    useEffect(() => {
        signinRedirectCallback()
            .then((user) => {
                history.push('/');
            })
            .catch((err) => {
                console.error(err);
                history.push('/');
            });
    }, []);
    return <span>Загрузка...</span>;
}
