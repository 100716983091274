import React, { useState } from 'react'
import AUTH_CONFIG from "Services/auth.js"
import { UserManager, WebStorageStateStore } from "oidc-client";
import UserManagerContext from './UserManagerContext';

const AuthUser = (props) => {

    const [userManager, setUserManager] = useState(new UserManager({
        ...AUTH_CONFIG,
        userStore: new WebStorageStateStore({ store: window.localStorage })
    }));

    userManager.events.addSilentRenewError((e) => {
        console.warn("Silent renew error", e.message);
        userManager.signinRedirect();
    });

    return (
        <UserManagerContext.Provider value={userManager}>
            {props.children}
        </UserManagerContext.Provider>
    );
};

export default AuthUser;
