import AUTH_CONFIG from "Services/auth.js"
import decode from 'jwt-decode';
import  { useContext} from 'react'
import { UserManagerContext } from 'components/Auth';

const useAuth = props => {

    const userManager = useContext(UserManagerContext);

    const checkAuth = () => {
        const token = getAccessToken();

        if (!token) {
            return false;
        }

        try {

            const { exp, nbf } = decode(token);
            const now = (new Date().getTime() / 1000) + 1;
            if (exp < now || nbf > now) {
                return false;
            }

        } catch (err) {
            console.error(err.error || err.message || err);
            return false;
        }

        return true;
    };

    const getUser =  () => {
        const oidcStorage = getOidcStorage();
        if (!oidcStorage) {
            return signinRedirect();
        }
        return oidcStorage;
    };

    const signinRedirect = () =>  userManager.signinRedirect();

    const logout = () => userManager.signoutRedirect();

    const signinRedirectCallback = () => userManager.signinRedirectCallback();

    const getOidcStorage = () => JSON.parse(localStorage.getItem(`oidc.user:${AUTH_CONFIG.authority}:${AUTH_CONFIG.client_id}`));

    const isAuthenticated = () => checkAuth();

    const getAccessToken = () => {
        const oidcStorage = getOidcStorage();
        const access_token = oidcStorage ? oidcStorage.access_token : '';
        return access_token;
    }

    const getClaims = () => {
        if (isAuthenticated()) {
            const user = getUser();
            return { ...decode(user.access_token), ...user.profile };
        }
        return {};
    }

    const createAuthorizedRequest = (method, body) => {

        const req = {
            method: method ? method : "GET",

            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getAccessToken(),
            },
        };
        if (body) {
            req.body = body;
        }
        return req;
    }

    return {
        logout,
        isAuthenticated,
        getAccessToken,
        createRequest: createAuthorizedRequest,
        getClaims,
        signinRedirect,
        signinRedirectCallback
    };
}

export default useAuth;
